@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.c-ttl-unit {
  margin-bottom: 30px;
  text-align: center;

  @include mixin.tabletP {
    margin-bottom: 10px;
  }
}

.ttl-main-en {
  color: variables.$COLOR_MAIN;
  font-family: variables.$FONT_ALPHA;
  font-weight: 800;
  letter-spacing: 2px;
}

.ttl-main-ja {
  font-size: calc(26px + 6 * (100vw - 320px) / 1600);
  font-weight: 900;
}

.ttl-sub-ja {
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  font-weight: 700;

  span {
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
  }
}

.p-news-ttl {
  font-family: variables.$FONT_ALPHA;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding: 0;
  position: relative;

  &::before {
    background-color: variables.$COLOR_MAIN;
    content: "";
    display: block;
    height: 1px;
    width: 15px;
    position: absolute;
    top: -10px;
    left: 0;
  }
}

/* 20230626カーリースLP(本サイト・軽自動車.com)  */
.cl-ttl-unit {
  margin-bottom: 30px;
  text-align: center;

  @include mixin.tabletP {
    margin-bottom: 20px;
  }
}

.cl-ttl {
  font-weight: 700;
  letter-spacing: .01em;
  line-height: normal;

  &__en {
    font-size: calc(36px + 18 * (100vw - 320px) / 1600);
    font-family: variables.$FONT_ALPHA;
  }

  &__ja {
    font-size: calc(22px + 8 * (100vw - 320px) / 1600);
    font-weight: 700;
    position: relative;
    z-index: 2;

    &::after {
      color: map_get(variables.$COLOR, gray);
      display: inline-block;
      font-size: calc(60px + 40 * (100vw - 320px) / 1600);
      font-family: variables.$FONT_ALPHA;
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &--sub {
      font-size: calc(28px + 8 * (100vw - 320px) / 1600);
      font-weight: 700;
    }

    +.cl-lead {
      margin-top: 20px;
    }

    &.bg-slash {
      &::after {
        display: none;
      }

      span {
        font-size: calc(16px + 14 * (100vw - 320px) / 1600);
        padding: 0 40px;
        position: relative;

        @include mixin.tabletP {
          padding: 0 20px;
        }

        &::before,
        &::after {
          background-size: 100% auto;
          content: "";
          display: block;
          width: 20px;
          height: 35px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @include mixin.tabletP {
            width: 14px;
            height: 25px;
          }
        }

        &::before {
          background: url(#{mixin.set_image_path('carlease/common/icon_slash_left_black.svg')}) no-repeat center center;
          left: 0;
        }

        &::after {
          background: url(#{mixin.set_image_path('carlease/common/icon_slash_right_black.svg')}) no-repeat center center;
          right: 0;
        }
      }
    }
  }
}

.c-balloon-ttl {
  border: 2px variables.$COLOR_TEXT dashed;
  border-radius: 16px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  padding: 8px 15px;
  position: relative;


  &::before {
    border-bottom: 2px dashed variables.$COLOR_TEXT;
    border-left: 2px dashed variables.$COLOR_TEXT;
    content: '';
    display: block;
    margin: 0 auto;
    width: 28px;
    height: 28px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    transform: rotate(-45deg);
  }
}

.modTitle {
  text-align: center;
  margin-bottom: 10px;

  .ttl__img {
    @include mixin.tabletP {
      max-height: 18px;
    }    
  }
}
