@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.p-container-lg {
  background-color: white;
  border-radius: 0 40px 0 0;
  padding: 25px 50px;
  width: 1545px;
  max-width: calc(100% - 15px);

  @include mixin.tabletL {
    gap: 0 30px;
    padding: 20px 15px;
  }

  @include mixin.tabletP {
    padding: 15px;
  }
}

.c-flex-unit {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 0 18px;

  @include mixin.tabletL {
    flex-wrap: wrap;
  }

  .c-flex-box1 {
    width: 100%;
  }

  .c-flex-box2 {
    width: calc(100% / 2);

    @include mixin.tabletL {
      width: 100%;
    }
  }

  .c-flex-box3 {
    width: calc(100% / 3);

    @include mixin.tabletL {
      width: 100%;
    }
  }

  [class^="c-flex-box"] {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include mixin.tabletL {
      flex-direction: row;
      justify-content: space-between;
      gap: 0 10px;
      flex-wrap: wrap;
    }
  }

  &.type2 {
    gap: 20px 18px;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include mixin.tabletP {
      gap: 20px 0;
    }

    .c-flex-box4 {
      width: calc(100% / 4 - 14px);

      @include mixin.tabletL {
        width: calc(100% / 3 - 12px);
      }

      @include mixin.tabletP {
        width: 100%;
      }
    }

    .ranking-card {
      background-color: white;
      box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
      border-bottom: none;
      flex-direction: column;
      text-decoration: none;
      width: 100%;
      max-width: 100%;
      color: unset;

      &:hover {
        color: variables.$COLOR_TEXT;

        .txt-link {
          color: variables.$COLOR_TEXT;

          &::after {
            right: 0;
            transition: .3s;
          }
        }
      }

      .ratio {

        &::before {
          padding-top: 66.6666%;
        }

        &.campaigns {

          &::after {
            background-color: rgba(223, 18, 26, .7);
            content: "今月の特選車";
            display: block;
            color: white;
            font-style: 14px;
            padding: 0 10px;
            text-align: center;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .card-body {
        padding: 20px 15px;
      }

      .card-footer {
        margin-top: auto;
        padding: 0 30px 20px;
        text-align: right;

        span {
          display: inline-block;
        }
      }

      .car-name {
        margin-bottom: 15px;
      }
    }
  }
}

.p-top-nav {
  box-sizing: border-box;
  display: flex;
  gap: 0 100px;

  &__block {

    .flex-unit {
      display: flex;
      flex-wrap: wrap;
      gap: 0 60px;
    }
  }
}

.p-bottom-nav {
  border-top: 1px map_get(variables.$COLOR, dark-gray) solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 auto;
  width: calc(100% - 30px);

  @include mixin.tabletP {
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0;
    width: 100%;
  }

  &__block {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include mixin.tabletP {
      justify-content: center;
      padding: 0 15px;
      text-align: center;
      width: 100%;
    }

    +.p-bottom-nav__block {
      @include mixin.tabletP {
        border-top: 1px map_get(variables.$COLOR, dark-gray) solid;
      }
    }
  }
}

.c-news-unit {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @include mixin.tabletP {
    flex-wrap: wrap;
  }

  &__item {
    padding: 20px 0;

    @include mixin.tabletP {
      padding: 10px 0;
      width: 100%;
    }

    +.c-news-unit__item {
      border-left: 1px #E5E4E5 solid;

      @include mixin.tabletP {
        border-left: none;
      }
    }
  }
}

.p-form-block {
  margin-bottom: 30px;
}

.p-tag-block {
  border-bottom: 1px white dashed;
  padding: 0 30px 50px;
  margin-bottom: 60px;

  @include mixin.tabletP {
    padding: 30px 15px;
  }
}

.p-link-block {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @include mixin.tabletP {
    flex-wrap: wrap;
  }

  +.p-link-block {
    margin-top: 20px;
  }

  &__item {

    @include mixin.tabletP {
      text-align: center;
    }

    &.col-left {
      width: 20%;

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &.col-right {
      width: 80%;

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.c-ranking-card {
  background-color: white;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  border-bottom: none;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  color: unset;

  &:hover {
    color: variables.$COLOR_TEXT;

    .txt-link {
      color: variables.$COLOR_TEXT;

      &::after {
        right: 0;
        transition: .3s;
      }
    }
  }

  .ratio {

    &::before {
      padding-top: 66.6666%;
    }

    &.campaigns {

      &::after {
        background-color: rgba(223, 18, 26, .7);
        content: "今月の特選車";
        display: block;
        color: white;
        font-style: 14px;
        padding: 0 10px;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

  }

  .card-body {
    padding: 20px 15px;
  }

  .card-footer {
    margin-top: auto;
    padding: 0 30px 20px;
    text-align: right;

    span {
      display: inline-block;
    }
  }

  .car-name {
    margin-bottom: 15px;
  }
}

/* 20230626カーリースLP(本サイト・軽自動車.com)  */
.bg-dot {
  &__pattern1 {
    background: url(#{mixin.set_image_path('carlease/common/bg_dot_pattern1.svg')}) repeat center top;
  }

  &__pattern2 {
    background: url(#{mixin.set_image_path('carlease/common/bg_dot_pattern2.svg')}) no-repeat center bottom;
    background-size: cover;

    @include mixin.tabletP {
      background: url(#{mixin.set_image_path('carlease/common/bg_dot_pattern2_sp.svg')}) no-repeat center bottom;
      background-size: 100% auto;
    }
  }

  &__pattern3 {
    background: url(#{mixin.set_image_path('carlease/common/bg_dot_pattern3.svg')}) no-repeat center top;
    background-size: cover;

    @include mixin.tabletP {
      background: url(#{mixin.set_image_path('carlease/common/bg_dot_pattern3_sp.svg')}) no-repeat center top;
      background-size: 100% auto;

    }
  }
}

.cl-link-block {

  &.b-bottom {
    border-bottom: 12px variables.$COLOR_TEXT solid;

    @include mixin.tabletP {
      padding-bottom: 30px;
    }

    .cl-link-unit {
      margin: 0 auto -4px;

      @include mixin.tabletP {
        margin: 0 auto;
      }

      li {
        a {
          border-radius: 12px 12px 0 0;
        }
      }
    }
  }

  &.b-top {
    border-top: 12px variables.$COLOR_TEXT solid;

    @include mixin.tabletP {
      padding-top: 30px;
    }

    .cl-link-unit {
      margin: -4px auto 0;

      @include mixin.tabletP {
        margin: 0 auto;
      }

      li {
        a {
          border-radius: 0 0 12px 12px;
        }
      }
    }
  }

  &.b-bottom,
  &.b-top {

    .cl-link-unit {
      li {
        a {
          @include mixin.tabletP {
            border-radius: 12px;
          }
        }
      }
    }
  }
}

.c-cl-mv {

  .cl-mv-img  {
    padding: 80px 0;
    text-align: center;

    @include mixin.tabletP {
      padding: 40px 15px;
    }
  }
}

.cl-card-block {

  &.type1 {
    gap: 65px 0;
    display: flex;
    flex-direction: column;

    @include mixin.tabletP {
      gap: 40px 0;
    }

    .cl-card {

      &.type-col2 {
        gap: 0 40px;
        flex-direction: row;
        flex-wrap: wrap;

        @include mixin.tabletP {
          flex-direction: column;
          gap: 20px 0;
        }

        .card-img,
        .card-body {
          width: calc(100% / 2 - 20px);

          @include mixin.tabletP {
            width: 100%;
          }
        }

        .card-img {
          border: 2px variables.$COLOR_TEXT solid;
          border-radius: 20px;
          overflow: hidden;
        }
      }
    }
  }

  &.type2,
  &.type3,
  &.type4 {
    display: flex;
    flex-wrap: wrap;
  }

  &.type2 {
    gap: 20px 30px;

    @include mixin.tabletP {
      gap: 20px 0;
    }

    .cl-card {
      width: calc(100% / 2 - 15px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  &.type3 {
    gap: 0 30px;

    @include mixin.tabletP {
      gap: 20px 0;
    }

    .cl-card {
      width: calc(100% / 3 - 20px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  &.type4 {
    gap: 0 20px;

    @include mixin.tabletP {
      gap: 10px 0;
    }

    .cl-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 20px;
      width: calc(100% / 4 - 15px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  &.bg-gray {
    background-color: map_get(variables.$COLOR, gray);
    border: 3px variables.$COLOR_TEXT solid;
  }
}

.cl-movie-block {
  border: 7px variables.$COLOR_TEXT solid;
  border-radius: 20px;
  margin: 0 auto;
  width: 690px;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 57.5714%;
  }

  img,
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.p-card-block {
  display: flex;

  @include mixin.tabletP {
    flex-wrap: wrap;
  }

  &.type2 {

    .c-card {
      width: calc(100% / 2);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  &.type3 {

    .c-card {
      width: calc(100% / 3);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  &.type4 {

    .c-card {
      width: calc(100% / 4);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  .c-card {
    text-decoration: none;
    padding: 0;
  }
}