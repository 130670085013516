@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';

ul.modListJump {
  box-sizing: border-box;
	display: flex;
  align-items: stretch;
	flex-wrap: wrap;
  gap: 10px 18px;
	padding: 0;
	margin-bottom: 80px;
  height:auto;

  @include mixin.tabletP {
    gap: 15px 0;
  }

	li{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height:auto;
    width: calc(100% / 4 - 15px);
    position: relative;

    @include mixin.tabletL {
      width: calc(100% / 2 - 10px);
    }

    @include mixin.tabletP {
      width: 100%;
    }

		&:before {
			display: none;
		}

		a {
      background-color: #EFF7FD;
      border-radius: 6px;
      color: variables.$COLOR_TEXT;
      display:flex;
      align-items: center;
      flex-grow: 1;
      line-height: normal;
			text-decoration: none;
			padding: 15px 20px;
      padding-right: 30px;
			position: relative;

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_circle_blue.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }

      &:hover {
        text-decoration: none;
      }

		}
	}
}

.c-news-unit {

  .c-news-list {
    display: flex;
    align-items: center;
    padding-left: 50px;

    @include mixin.tabletL {
      flex-wrap: wrap;
      gap: 5px 0;
    }

    @include mixin.tabletP {
      padding-left: 0;
    }

    dt,dd {
      font-weight: 400;
      line-height: 1;

      @include mixin.tabletL {
        line-height: normal;
      }
    }

    .date {
      width: 150px;

      @include mixin.tabletL {
        width: 100%;
      }
    }

    dd {
      width: calc(100% - 150px);

      @include mixin.tabletL {
        width: 100%;
      }

      .post-link {
        display: -webkit-box;
        text-decoration: none;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        width: calc(100% - 150px);

        @include mixin.tabletP {
          width: 100%;
        }

        &:hover {
          color: variables.$COLOR_TEXT;
        }
      }
    }
  }
}

.c-tag-list {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;

  .tag-link {
    color: white;
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    text-decoration: none;

    &::before {
      content: "#";
      display: inline-block;
    }
  }
}

.c-search-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 20px;
  flex-wrap: wrap;
  width: 100%;

  li {
    width: calc(100% / 5 - 16px);

    .search-link {
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
      display: inline-block;
      width: 100%;

      &.b-radius1 {
        border-radius: 26px;

        @include mixin.tabletL {
          border-radius: 47px
        }
      }

      &.b-radius2 {
        border-radius: 16px;
      }

      img {
        width: 100%;
      }
    }
  }

  &.type1 {
    @include mixin.tabletP {
      gap: 10px;
    }

    li {
      @include mixin.tabletL {
        width: calc(100% / 4 - 15px);
      }

      @include mixin.tabletP {
        width: calc(100% / 2 - 5px);
      }
    }
  }

  &.type2 {

    li {

      @include mixin.tabletL {
        width: calc(100% / 4 - 15px);
      }

      @include mixin.tabletP {
        width: calc(100% / 3 - 14px);
      }
    }
  }
}

.p-detail-list {

  li {

    &::before,
    &::after {
      display: inline-block;
    }

    &.model {

      &::before {
        content: "型式：";
      }
    }

    &.model-year {
      &::before {
        content: "年式：";
      }

      &::after {
        content: "年";
      }
    }

    &.mileage {
      &::before {
        content: "走行距離：";
      }

      &::after {
        content: "km";
      }
    }
  }
}

.p-point-list {
  /* margin-bottom: 30px; */

  li {
    background-color: map_get(variables.$COLOR, gray);
    text-align: center;
    font-weight: 700;
    padding: 10px;

    +li {
      margin-top: 5px;
    }
  }
}


.list__tabl {

  li {
    border-bottom: 1px #aaa solid;
    display: flex;
    gap: 0 20px;
    padding: 15px;

    span {
     
      &:first-child {
        font-weight: 700;
        width: 30%;
      }

      &:last-child {
        width: calc(70% - 15px);
      }
    }
  }
}
