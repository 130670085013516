// color
$COLOR: (
  turquoise: #008DD0,
  blue: #0066C0,
	gray: #EDEDED,
  dark-gray: #aaa,
  palegreen: #d8eae0,
  green: #5FA867,
  yellow: #E8B300,
  gold: #f1b428,
) !default;

$COLOR_TEXT: #333;
$COLOR_MAIN: #DF121A;


// base
$BASE: (
	font-size: 16px,
	line-height: 1.8,
) !default;


// brake-point
$BRAKE_POINT: (
  sp: 578px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_ALPHA: 'Montserrat', Arial, Helvetica, sans-serif;
$FONT_ALPHA_SPARTAN: 'Spartan', Arial, Helvetica, sans-serif;
$FONT_MINCHO: "Ryumin Medium KL", '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
$FONT_NUMBER: 'Barlow', sans-serif;
