@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.c-schedule-tbl {
  background-color: white;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  margin: 0 auto;
  width: 990px;
  max-width: 100%;

  @include mixin.tabletP {
    width: 100%;
  }

  th,td {
    background-color: white;
    border: 1px map_get(variables.$COLOR, dark-gray) solid;
    color: variables.$COLOR_TEXT;
    display: table-cell;
    font-size: calc(12px + 6 * (100vw - 320px) / 1600);
    font-weight: 700;
    text-align: center;
  }

  .empty-cell,
  .week-block__last {
    border-right-width: 2px;
  }

  thead {
    border-bottom: 2px map_get(variables.$COLOR, dark-gray) solid;

    th {
      padding: 10px 0;

      @include mixin.tabletP {
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11),
        &:nth-of-type(12),
        &:nth-of-type(13),
        &:nth-of-type(14){
          display: none;
        }
        &:nth-of-type(7){
          border-right-width: 1px;
        }
      }

      &:nth-of-type(14){
        border-right-width: 1px;
      }

      &.empty-cell {
        width: 86px;

        @include mixin.tabletP {
          width: 60px;
        }

        @include mixin.sp {
          width: 45px;
        }
      }

      &.date-cell {

        &[colspan="1"] {
          font-size: calc(8px + 2 * (100vw - 320px) / 1600);
        }

        @include mixin.tabletP {
          &[colspan="7"] {
            +.date-cell {
              display: none;
            }
          }
        }

        +.date-cell {
          border-left-width: 2px;
        }

      }

      span {
        line-height: 1.2;
        display: block;
        text-align: center;

        &.week {
          font-size: calc(10px + 4 * (100vw - 320px) / 1600);

          &::before,
          &::after {
            display: inline-block;
          }

          &::before {
            content: "(";
          }

          &::after {
            content: ")";
          }
        }
      }
    }
  }

  tbody {

    tr {
      &.breaktime-cell {
        th,td {
          border-bottom-width: 2px;
        }
      }
    }

    th {
      border-right-width: 2px;
    }

    td {

      @include mixin.tabletP {
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11),
        &:nth-of-type(12),
        &:nth-of-type(13),
        &:nth-of-type(14){
          display: none;
        }
        &:nth-of-type(7){
          border-right-width: 1px;
        }
      }

      &:nth-of-type(14){
        border-right-width: 1px;
      }

      span {
        padding: 10px;
        text-align: center;

        @include mixin.sp {
          padding: 10px 5px;
        }

        &.closed-cell {
          background-color: map_get(variables.$COLOR, gray);
          &::before {
            background: url(#{mixin.set_common_path('icon_closed.svg')}) no-repeat center center;
            background-size: 100% auto;
            content: "";
            display: block;
            margin: 0 auto;
            width: 20px;
            height: 20px;

            @include mixin.sp {
              width: 10px;
              height: 10px;
            }
          }
        }

        &.open-cell {
          button,
          a {
            display: block;
            margin: 0 auto;

            &::before {
              background: url(#{mixin.set_common_path('icon_open.svg')}) no-repeat center center;
              background-size: 100% auto;
              content: "";
              display: block;
              margin: 0 auto;
              width: 20px;
              height: 20px;

              @include mixin.sp {
                width: 10px;
                height: 10px;
              }
            }

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }

  }
}

.p-form-unit {
  background-color: map_get(variables.$COLOR, gray);
  border-radius: 16px;
  padding: 40px 30px;

  @include mixin.tabletP {
    padding: 30px 15px;
  }

  .form-tbl {
    display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;

    th {
      color: variables.$COLOR_TEXT;
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      font-weight: 400;
    }

    td {
      padding-bottom: 30px;

      input {

        &[type="radio"],
        &[type="check"] {
          display: none;
        }
      }

      .custom-select {
        width: 400px;
        max-width: 100%;
      }

      label {
        @include mixin.tabletP {
          display: block;
        }
      }
    }
  }

  .form-row {
    .input-wrap {

      +.input-wrap {

        @include mixin.tabletP {
          margin-top: 10px;
        }
      }
    }
  }

  .pp-txt {
    margin-bottom: 30px;

    a {
      color: variables.$COLOR_TEXT;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.modTable {

  thead {
    th {
      background-color: #696969;
      border: 1px #696969 solid;
      color: white;
      font-weight: 700;
    }
  }

  tbody {
    th {
      background-color: map_get(variables.$COLOR, gray);
      border: 1px #A7A7A7 solid;
    }
  }

  th {
    text-align: center;
    font-weight: 700;

    @include mixin.tabletP {
      text-align: left;
    }
  }

  td {
    background-color: white;
    border: 1px #A7A7A7 solid;
  }

  th,
  td {
    padding: 10px 20px;
  }
}
