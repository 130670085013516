@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#header {
  display: flex;
  align-items: center;
  transition: 0.3s ease;

  .navbar-brand {
    width: calc(276 / 1920 * 100vw);
    max-width: 276px;
    min-width: 135px;
  }

  .navbar {
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 12px 30px 0;

    @include mixin.tabletL {
      padding: 12px 20px 0;
    }

    @include mixin.tabletP {
      padding: 10px 15px;
    }
  }

  .collapse-inner {

    @include mixin.tabletP {
      height: 100vh;
      overflow-y: auto;
    }
  }

  .navbar-nav {
    align-items: center;
    justify-content: flex-end;

    &.navbar-nav-top {
      margin-bottom: 5px;

      .nav-link {
        padding: 0 16px;

        @include mixin.tabletL {
          padding: 0 8px;
        }

        &:hover {
          color: variables.$COLOR_TEXT;
          opacity: .7;
        }

        &.link-btn {
          padding: 0;
          padding-right: 10px;
        }
        &.link-reserve {
          padding: 0;
        }
      }
    }

    &.navbar-nav-bottom {
      gap: 0 32px;

      @include mixin.tabletL {
        gap: 0 16px;
      }

      @media screen and (max-width: 897px) {
        gap: 0 10px;
      }     

      .nav-link {
        font-weight: 500;
        padding-bottom: 20px;
        position: relative;

        &::after {
          background:variables.$COLOR_MAIN;
          content: '';
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all .3s;
          transform: scale(0, 1);
          transform-origin: left top;
        }

        &:hover {

          &::after {
            transform: scale(1, 1);
          }
        }
      }
    }
  }

  .nav-scroller {
    height: 2.75rem;
    overflow-y: hidden;
    position: relative;
    z-index: 2;

    .nav {
      color: rgba(255, 255, 255, .75);
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 1rem;
      margin-top: -1px;
      text-align: center;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;
    }
  }

  #navbar-pc {
    @include mixin.tabletP {
      display: none !important;
    }
  }

  #navbar-sp {

    .collapse-inner {
      padding-bottom: 150px;
    }

    &.offcanvas-collapse {

      @include mixin.tabletP {
        background-color: variables.$COLOR_TEXT;
        width: 660px;
        max-width: 85%;
        position: fixed;
        bottom: 0;
        right: 0;
        transform: translateX(100%);
        overflow-y: auto;
        transition: .3s ease-out;
      }

      &.open {
        @include mixin.tabletP {
          visibility: visible;
          transform: translateX(0);
        }
      }

      .nav-item {
        width: 100%;
      }

      .nav-link {
        color: white;
        font-family: variables.$FONT_ALPHA;
        font-weight: 800;
      }
    }

    /* ドロップダウンメニュー */
    .accordion {
      padding-left: 0 !important;
      margin-bottom: 50px;

      .card{
        background-color: transparent;
        border-bottom: 1px map_get(variables.$COLOR, gray) solid;
      }

      .card-header {
        padding: 20px;
      }

      .heading {
        color: white;
        display: block;
        font-family: variables.$FONT_ALPHA;
        font-weight: 800;
        padding: 20px;
        text-align: left;
        text-decoration: none;
        width: 100%;
      }

      .accordion-menu {
        padding-left: 30px;

        li {
          margin-bottom: 20px;
        }
      }
    }

    /* ドロップダウントグル */
    .accordion-toggle {
      color: white;
      display: block;
      font-family: variables.$FONT_ALPHA;
      font-weight: 800;
      text-align: left;
      text-decoration: none;
      width: 100%;
      position: relative;

      &[data-toggle="collapse"] {

        &::after {
          background: url("#{mixin.set_image_path('header/nav_dropdown.svg')}") no-repeat center center;
          background-size: 100% auto;
          content: "";
          display: block;
          width: 10px;
          height: 18px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: .3s;
        }
      }

      &[aria-expanded="false"] {
        &::after {
          transform: translateY(-50%) rotate(0);
          transition: .3s;
        }
      }
      &[aria-expanded="true"] {
        &::after {
          transform: translateY(-50%) rotate(-90deg);
          transition: .3s;
        }
      }
    }
  }

  .nav-link {
    /* font-size: calc(14px + 2 * (100vw - 320px) / 1600); */
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    text-decoration: none;
    line-height: normal;
    padding: 0;

    @include mixin.tabletP {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    &:hover {
      color: variables.$COLOR_MAIN;
      opacity: 1;
    }
  }

  /* ナビトグル */
  .navbar-toggler {
    border-radius: 0;
    border: none;
    padding: 5px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 9999;
  }

  .navbar-toggler-icon {
    background-image: none;
    background-color: variables.$COLOR_TEXT;
    width: 30px;
    height: 2px;
    display: block;
    position: absolute;
    transition: ease .5s;
    right: 0;
  }

  .navbar-toggler[aria-expanded="false"] {
    .navbar-toggler-icon {
      &:nth-of-type(1) {
        width: calc(100% - 16px);
        top: 17px;
      }

      &:nth-of-type(2) {
        width: calc(100% - 8px);
        top: 25px;
      }

      &:nth-of-type(3) {
        width: 100%;
        top: 33px;
      }
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon {

      &:nth-of-type(1) {
        width: 100%;
        top: 25px;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        transform:scaleX(0);
      }

      &:nth-of-type(3) {
        width: 100%;
        top: 25px;
        transform: rotate(135deg);
      }
    }
  }

  .nav-btn-unit {
    padding: 0 20px;

    li {

      +li {
        margin-top: 15px;
      }

      a {
        display: block;

        img {
          width: auto;
        }
      }
    }
  }

}
