@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

[aria-hidden="false"] {
  display: block;
}

[aria-hidden="true"] {
  display: none;
}

[data-area="pc"] {
  display: block;

  @include mixin.tabletP {
    display: none;
  }
}

[data-area="sp"] {
  display: none;

  @include mixin.tabletP {
    display: block;
  }
}

.bg-gray {
  background-color: map_get(variables.$COLOR, gray);
}

.bg-paleblue {
  background-color: #EFF5FB;
}

.box-shadow-sm {
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
}

.box-shadow-lg {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
}

.p-btn-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px 15px;

  @include mixin.tabletP {
    flex-direction: column;
    flex-wrap: wrap;
  }

}

.car-maker,
.car-name {
  line-height: normal;
}

.car-maker {
  font-size: calc(10px + 6 * (100vw - 320px) / 1600);
}

.car-name {
  font-size: calc(12px + 6 * (100vw - 320px) / 1600);
  font-weight: 700;
}

.price {
  color: variables.$COLOR_MAIN;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: 1.2;

  &::before,
  &::after {
    color: variables.$COLOR_TEXT;
    font-size: calc(10px + 4 * (100vw - 320px) / 1600);
    font-weight: 400;
  }

  &::before {
    content: "月額";
    display: block;
  }

  &::after {
    content: "円(税込)~";
    display: inline-block;
  }

  &.used_price {
    &::before {
      content: "価格";
    }
  }
}

.c-stepper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 30px;
  width: 450px;
  position: relative;

  @include mixin.tabletP {
    width: 100%;
  }

  &__item {
    counter-increment: steps;
    color: map_get(variables.$COLOR, dark-gray);
    font-family: variables.$FONT_BASE;
    font-size: calc(10px + 4 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    text-align: center;
    width: calc(100% / 4);
    position: relative;

    &:before {
      border-radius: 50%;
      background-color: map_get(variables.$COLOR, dark-gray);
      color: white;
      content: counter(steps);
      display: block;
      font-family: variables.$FONT_ALPHA;
      font-size: calc(14px + 4 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: 36px;
      margin: 0 auto 5px;
      text-align: center;
      width: 36px;
      height: 36px;

      @include mixin.sp {
        line-height: 26px;
        height: 26px;
        width: 26px;
      }
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_dots.svg')}) no-repeat center center;
      content: "";
      width: 100%;
      height: 5px;
      position: absolute;
      top: 15px;
      left: -50%;
      z-index: -1;

      @include mixin.sp {
        top: 10px;
      }
    }

    &:first-child {
      &:after {
        content: none;
      }
    }

    &.active {
      color: variables.$COLOR_MAIN !important;

      &::before {
        background-color: variables.$COLOR_MAIN !important;
      }
    }
  }
}

.p-apple-unit {
  border: 4px variables.$COLOR_TEXT solid;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px;

  @include mixin.tabletL {
    text-align: center;
  }

  @include mixin.tabletP {
    padding: 30px 0;
  }

  &__item {

    &.col-lg-8 {

      .col-left {
        width: calc(100% - 230px);

        @include mixin.tabletL {
          width: 100%;
        }
      }

      .col-right {
        position: absolute;
        bottom: -44px;
        right: 0;
      }
    }
  }

  &__ttl {
    margin-bottom: 20px;

    @include mixin.tabletL {
      margin-bottom: 5px;
    }
  }

  .appli-btn-unit {
    display: flex;
    align-items: center;
    gap: 10px;

    @include mixin.tabletL {
      justify-content: center;
      margin-bottom: 30px;
    }
  }
}

.p-apple-img-sp {
  text-align: center;

  img {
    max-width: calc(100% - 100px);
  }
}

.custom-btn-wrap {
  margin-top: 60px;
  text-align: center;

  .custom-btn {
    background-color: variables.$COLOR_MAIN;
    border-radius: 6px;
    color: white;
    font-size: calc(18px + 10 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    margin: 0 auto;
    padding: 20px;
    width: 600px;
    max-width: 100%;
  }
}

/* 20230626カーリースLP(本サイト・軽自動車.com)  */
.cl-link-unit {
  align-items: center;
  justify-content: center;
  gap: 0 12px;
  width: 1200px;
  max-width: 100%;

  @include mixin.tabletP {
    flex-wrap: wrap;
    gap: 15px 0;
    margin: 0 auto;
  }

  li {
    display: flex;
    align-items: stretch;
    text-align: center;
    width: calc(100% / 3 - 8px);

    @include mixin.tabletP {
      width: 100%;
    }

    a {
      border: 1px variables.$COLOR_TEXT solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      padding: 26px 5px;
      text-decoration: none;
      width: 100%;

      @include mixin.tabletP {
        min-height: auto;
        padding: 20px 5px;
      }

      &:hover,
      &.active {
        background-color: variables.$COLOR_TEXT;
        color: #ffd52d;
      }
      &:hover {
        &.active {
          pointer-events: none;
          opacity: 1;
        }
      }
    }
  }
}

.cl-info-unit {
  gap: 0 10px;
  margin-top: 50px;

  @include mixin.tabletP {
    gap: 20px 0;
    flex-wrap: wrap;
  }
  &__link {
    border: 1px variables.$COLOR_TEXT solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(18px + 6 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding: 20px 10px;
    text-decoration: none;
    width: 100%;

    span {

      &.small {
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
      }

      &.info-icon {
        padding-right: 30px;
        position: relative;

        &::after {
          background-size: 100% auto;
          content: "";
          display: block;
          width: 20px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }

    &:hover {
      color: variables.$COLOR_TEXT;
    }
  }

  li {
    display: flex;
    width: calc(100% / 2 - 10px);

    @include mixin.tabletP {
      width: 100%;
    }

    &.lease-item {

      .cl-info-unit__link {

        .info-icon {
          &::after {
            background: url(#{mixin.set_image_path('carlease/common/icon_info_img1.svg')}) no-repeat center center;
            height: 35px;
            transform: translateY(calc(-50% - 2px));
          }
        }
      }
    }

    &.used-item {

      .cl-info-unit__link {

        .info-icon {
          &::after {
            background: url(#{mixin.set_image_path('carlease/common/icon_info_img2.svg')}) no-repeat center center;
            height: 16px;
          }
        }
      }
    }
  }
}

.cl-card {
  border-radius: 20px;
  padding: 30px;
  position: relative;

  @include mixin.tabletP {
    padding: 30px 15px;
  }

  &.bg-gray {
    background-color: map_get(variables.$COLOR, gray);
    border: 3px variables.$COLOR_TEXT solid;
  }

  .card-heading {
    text-align: center;
    width: 100%;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);

    @include mixin.tabletP {
      top: -18px;
    }

    span {
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 59%, rgba(255,255,255,0) 59%);
      color: variables.$COLOR_MAIN;
      font-size: 24px;
      font-weight: 700;
      padding: 0 40px;
      position: relative;

      @include mixin.tabletP {
        background: linear-gradient(180deg, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 65%);
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        padding: 0 30px;
      }

      &::before,
      &::after {
        background-size: 100% auto;
        content: "";
        display: block;
        width: 13px;
        height: 28px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        background: url(#{mixin.set_image_path('carlease/common/icon_slash_left.svg')}) no-repeat center center;
        left: 16px;

        @include mixin.tabletP {
          left: 10px;
        }
      }

      &::after {
        background: url(#{mixin.set_image_path('carlease/common/icon_slash_right.svg')}) no-repeat center center;
        right: 16px;

        @include mixin.tabletP {
          right: 10px;
        }
      }
    }
  }

  &__item {
    width: calc(100% / 4 - 23px);

    @include mixin.tabletP {
      width: 100%;
    }
  }
}

.border-blue {
  border: 2px map_get(variables.$COLOR, turquoise) solid;
}

.bg-blue {
  background-color: map_get(variables.$COLOR, turquoise);
}

/*
[data-maker="1"] {

  .ranking-card {
    .card-img {
      position: relative;

      &::after {
        background-color: rgba(223, 18, 26, .7);
        bottom: 0;
        color: #fff;
        content: "現在出荷を停止しております。";
        display: block;
        font-size: 14px;
        left: 0;
        padding: 0 10px;
        position: absolute;
        text-align: center;
        width: 100%;
      }

    }
  }
}
*/

.modFlex {
  display: flex;
  align-items: center;

  li {
    font-size: calc(14px + 2 * (100vw - 320px) / 1600);
    font-weight: 400;
  }

  label {
    margin-bottom: 0;
  }

  .label {
    padding-right: 0;
  }
}


.modCardBlock {
  border-top: 1px #707070 solid;
  display: flex;
  flex-direction: column;
  margin: 30px auto 50px;

  @include mixin.tabletP {
    margin: 30px auto;
  }

  .card {

    &.modCard {
      border-bottom: 1px #707070 solid;
      color: variables.$COLOR_TEXT;
      flex-direction: row;
      gap: 0 16px;
      padding: 32px 0;
      text-decoration: none;

      @include mixin.tabletP {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px 0;
      }

      .card-img {
        width: 280px;

        @include mixin.tabletP {
          width: 100%;
        }
      }

      .card-body {
        width: calc(100% - 280px);

        @include mixin.tabletP {
          width: 100%;
        }

        .card-header {
          margin-bottom: 20px;

          .card-title {
            font-size: clamp(1rem, 0.875rem + 0.63vw, 1.625rem);
            font-weight: 700;
            line-height: 1.346;
          }
        }
      }

      .card-header,
      .card-footer {
        .card-title {
          margin-bottom: 0;
        }
      }

      .card-footer {
        margin-top: 18px;

        .card-title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

    }


  }
}

.tagBlock {
  display: flex;
  align-items: center;
  gap: 0 6px;
  margin-bottom: 10px;

  &[aria-hidden="false"] {
    display: flex;
  }

  [class^="tag"] {
    &[aria-hidden="false"] {
      display: inline-block;

      &::before {
        display: inline-block;
      }
    }
  }

  .tag1 {
    &::before {
      content: "今月の特選車";
      border: 1px variables.$COLOR_MAIN solid;
      color: variables.$COLOR_MAIN;
      padding: 2px 20px;
    }
  }

  .tag2 {
    &::before {
      content: "NEW";
      background-color: variables.$COLOR_MAIN;
      color: white;
      font-size: clamp(1rem, 0.975rem + 0.13vw, 1.125rem);
      font-weight: 700;
      padding: 0 10px;
    }
  }
}

.priceBlock {

  .term,
  .price {
    margin-bottom: 0;
  }

  .term {
    font-size: clamp(1rem, 0.975rem + 0.13vw, 1.125rem);
    font-weight: 700;
  }

  .price {

    &.type2 {
      display: flex;
      align-items: baseline;
      gap: 0 5px;
      font-size: clamp(1.75rem, 1.7rem + 0.25vw, 2rem);
      font-weight: 900;

      &::before,
      &::after {
        color: variables.$COLOR_MAIN;
        display: inline-block;
        font-weight: 600;

        @include mixin.tabletP {
          font-size: 14px;
        }
      }
    }

  }
}

.summaryBlock {
  display: flex;
  gap: 0 10px;

  @include mixin.tabletL {
    flex-wrap: wrap;
    gap: 20px 0;
  }

  &__item {
    width: calc(100% / 2);

    @include mixin.tabletL {
      width: 100%;
    }
  }
}


.conditionBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    gap: 0 6px;
    width: calc(100% / 2 - 8px);
  }

  &__ttl {
    background-color: map_get(variables.$COLOR, gray);
    font-size: clamp(0.75rem, 0.705rem + 0.23vw, 0.875rem);
    padding: 0 4px;
    text-align: center;
    width: 80px;

    @include mixin.tabletP {
      width: 60px;
    }
  }

  &__txt {
    font-size: clamp(0.875rem, 0.739rem + 0.68vw, 1.25rem);
    font-weight: 700;
  }
}

.cost__card {
  background-color: #EFF5FB;
  border-radius: 12px;
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  padding: 20px 16px;
  margin-top: 30px;

  &-header {
    background-color: map_get(variables.$COLOR, blue);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
  }

  &-title {
    color: white;
    font-size: clamp(1.125rem, 1.05rem + 0.38vw, 1.5rem);
    font-weight: 700;
  }

  &-body {
    .modFlex {
      gap: 0 14px;
      justify-content: center;

      @include mixin.tabletP {
        flex-wrap: wrap;
        gap: 14px;
      }

      li {
        width: calc(100% / 5);

        @include mixin.tabletP {
          width: calc(100% / 2 - 10px);
        }

      }
    }
  }
}

//↓10/31 追加
.overlay-container {
    position: relative;
    display: inline-block;
}

.overlay-container img {
    display: block; /* 画像の余白をなくす */
    width: 100%; /* 必要に応じて設定 */
    height: auto; /* 必要に応じて設定 */
}

.overlay-container .overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒 */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    text-align: center;
    pointer-events: none; /* クリックを妨げないように */
}
