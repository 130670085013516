@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* form
------------------------------------------------------------*/
form {
  width: 100%;
  max-width: 100%;
}

textarea,
input,
input[type="password"],
input[type="email"] input[type="text"],
input[type="tel"] {
  background-color: white;
  box-sizing: border-box;
  border: 1px variables.$COLOR_TEXT solid;
  border-radius: 6px;
  color: variables.$COLOR_TEXT;
  display: inline-block;
  font-size: 16px; /* スマホ時拡大防止のためフォントサイズ固定 */
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @include mixin.tabletP {
    padding: 10px 20px;
  }

  &.error {
    background-color: rgba(255, 242, 242, 1.00);
    border: solid 2px variables.$COLOR_MAIN;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #dbdbdb;
    font-size: calc(14px + 4 * (100vw - 375px) / 1545);
    font-weight: normal;
  }

  &:-ms-input-placeholder {
    color: #dbdbdb;
    font-size: calc(14px + 4 * (100vw - 375px) / 1545);
    font-weight: normal;
  }
}

label {
  vertical-align: middle;
  margin-bottom: 0;
}

select {
  color: variables.$COLOR_TEXT;

  &.custom-select {
    background: #fff url(#{mixin.set_common_path('icon_pulldown.svg')}) right 20px center/19px 11px no-repeat;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding: 25px 15px;
    text-align: center !important;
    height: auto;

    @include mixin.tabletP {
      background: #fff url(#{mixin.set_common_path('icon_pulldown.svg')}) right 20px center/13px 7px no-repeat;
      padding: 15px;
    }
  }

  &.modSelect {
    background: #fff url(#{mixin.set_common_path('icon_pulldown.svg')}) right 10px center/12px 7px no-repeat;
    border: 1px #707070 solid;
    border-radius: 6px;
    box-shadow: none;
    font-size: calc(14px + 2 * (100vw - 320px) / 1600);
    font-weight: 400;
    padding: 0 15px;
    height: 40px;
    width: 180px;
    max-width: 100%;

    @include mixin.tabletP {
      width: 140px;
    }
  }
}

/* radio
-------------*/
input[type="radio"] {
  width: auto;
  /* @include accessibilityHidden; */
  &+.label {
    cursor: pointer;
    display: inline-block;
    padding: 0 24px;
    padding-right: 40px;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      border-radius: 100%;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all .2s;
    }

    &::before {
      background: white;
      border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
      height: 20px;
      width: 20px;
      left: 0;
    }

    &::after {
      background: white;
      width: 6px;
      height: 6px;
      left: 7px;
      top: 11px;
      transform: scale(0.5);
      opacity: 0;
    }

    &:hover {
      &::before {
        background: #F9F7F7;
      }
    }
  }

  &.error {
    &+.label {
      &::before {
        background-color: rgb(255, 242, 242);
        border: 1px solid variables.$COLOR_MAIN;
      }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background: map_get(variables.$COLOR, blue);
        border-color: map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/* checkbox
-------------*/
input[type="checkbox"] {
  width: auto;
 /*  @include accessibilityHidden; */

  &+.label {
    cursor: pointer;
    display: inline-block;
    padding: 0 24px;
    padding-right: 40px;
    position: relative;
    transition: all .2s;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      background: white;
      border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
      border-radius: 0;
      height: 20px;
      width: 20px;
      top: 50%;
      left: 0;
      transform: translateY(calc(-50% - 1px));
    }

    &::after {
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      width: 12px;
      height: 9px;
      top: 6px;
      left: 4px;
      transform: rotate(-45deg) scale(0.5);
      opacity: 0;
    }

    &:hover {
        &::before {
          background: #fff;
        }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background: map_get(variables.$COLOR, blue);
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
      }
    }
  }
}

/* ==[ contact form7 ]==================================== */
/* ラジオボタン・チェックボックス共通 */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
}

/* チェックボックス */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {

    &.wpcf7-acceptance,
    &.wpcf7-checkbox {
      display: flex;
      flex-wrap: wrap;

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
      }

      span.wpcf7-list-item {
        position: relative;

        &.first {
          margin: 0 !important
        }
      }

      .wpcf7-list-item-label {
        cursor: pointer;
        display: inline-block;
        padding: 0 24px;
        padding-right: 40px;
        position: relative;
        transition: all .2s;

        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::before {
          background: white;
          border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
          border-radius: 0;
          height: 20px;
          width: 20px;
          top: 50%;
          left: 0;
          transform: translateY(calc(-50% - 1px));
        }

        &::after {
          border-bottom: 3px solid white;
          border-left: 3px solid white;
          width: 12px;
          height: 9px;
          top: 8px;
          left: 4px;
          transform: rotate(-45deg) scale(0.5);
          opacity: 0;
        }
      }

      input[type="checkbox"]:checked+.wpcf7-list-item-label {
        &::before {
          background: map_get(variables.$COLOR, blue);
          border: 1px solid map_get(variables.$COLOR, blue);
        }

        &::after {
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        }
      }
    }
  }
}

/* ラジオボタン */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {
    &.wpcf7-radio {

      span.wpcf7-list-item {
        position: relative;

        &.first {
          margin: 0 !important
        }
      }

      .wpcf7-list-item-label {
        cursor: pointer;
        display: inline-block;
        padding: 0 24px;
        padding-right: 40px;
        position: relative;
        width: 100%;

        &::before,
        &::after {
          border-radius: 100%;
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all .2s;
        }

        &::before {
          background: white;
          border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
          height: 20px;
          width: 20px;
          left: 0;
        }

        &::after {
          background: white;
          width: 6px;
          height: 6px;
          left: 7px;
          top: 11px;
          transform: scale(0.5);
          opacity: 0;
        }

        &:hover {
          &::before {
            background: #F9F7F7;
          }
        }
      }

      input[type="radio"] {
        position: absolute;
        opacity: 0;
      }

      input[type="radio"]:checked+.wpcf7-list-item-label {
        &::before {
          background: map_get(variables.$COLOR, blue);
          border-color: map_get(variables.$COLOR, blue);
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

/* ajax loader */
.wpcf7 {
  .ajax-loader {
    display: block !important;
    text-align: center;
    margin: 0 auto !important;
  }
}

/* ==[ MW WP Form ]==================================== */
/* ラジオボタン */
.mwform-radio-field {
  margin: 0;

  input[type=radio] {
    appearance: none;
    display: none;

    + span {
      cursor: pointer;
      display: inline-block;
      padding: 0 24px;
      padding-right: 40px;
      position: relative;
      width: 100%;

      &::before,
      &::after {
        border-radius: 100%;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }

      &::before {
        background: white;
        border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
        height: 20px;
        width: 20px;
        left: 0;
      }

      &::after {
        background: white;
        width: 6px;
        height: 6px;
        left: 7px;
        top: 11px;
        transform: scale(0.5);
        opacity: 0;
      }

      &:hover {
        &::before {
          background: #F9F7F7;
        }
      }
    }

    &:checked {
      + span {
        &::before {
          background: map_get(variables.$COLOR, blue);
          border-color: map_get(variables.$COLOR, blue);
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

/* チェックボタン */
.mwform-checkbox-field {
  margin: 0;

  input[type=checkbox] {
    appearance: none;
    display: none;

    + .mwform-checkbox-field-text {
      cursor: pointer;
      display: inline-block;
      padding: 0 24px;
      padding-right: 40px;
      position: relative;
      transition: all .2s;

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        background: white;
        border: 1px solid map_get(variables.$COLOR, dark-gray)#aaa;
        border-radius: 0;
        height: 20px;
        width: 20px;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% - 1px));
      }

      &::after {
        border-bottom: 3px solid white;
        border-left: 3px solid white;
        width: 12px;
        height: 9px;
        top: 8px;
        left: 4px;
        transform: rotate(-45deg) scale(0.5);
        opacity: 0;
      }
    }

    &:checked {
      + .mwform-checkbox-field-text {
        &::before {
          background: map_get(variables.$COLOR, blue);
          border: 1px solid map_get(variables.$COLOR, blue);
        }

        &::after {
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        }
      }
    }
  }
}


/* 必須badge */
.required {

  &::after {
    color: variables.$COLOR_MAIN;
    content: "※";
    display: inline-block;
    padding-left: 5px;
  }
}


