@use "variables" as variables;
@use "mixin" as mixin;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&family=Noto+Sans+JP:wght@400;500;700;900&display=swap');

body {
  &.noScroll {
    overflow: hidden;
    height: 100vh;
    left: 0;
    position: fixed;
    width: 100%;

    &:before {
      content: '';
      background: rgba( 0, 0, 0, 0.4 );
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 500;
    }
  }
}

/* link
------------------------------------------------------------*/
button,
a {
  color: variables.$COLOR_TEXT;
  display: inline-block;
  vertical-align: baseline;
  text-decoration: underline;
  transition: 0.5s ease;

  /* ▼ 下線跡切れ対策 ▼ */
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;

  &:hover {
    color: variables.$COLOR_MAIN;
    opacity: .75;
    text-decoration: none;
    transition: .5s ease;
  }

  &:visited,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none !important;
  }

  [href$=".png"],
  [href$=".jpg"],
  [href$=".jpeg"],
  [href$=".jpe"],
  [href$=".gif"],
  [href$=".bmp"],
  [href$=".ico"] {
    border: none;
  }
}

button {
  margin: 0;
  padding: 0;
  &:hover {
      opacity: .7;
  }
}

img,
svg {
  width: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  flex-shrink: 0;
  -ms-interpolation-mode: bicubic;

  &[src$=".svg"] {
    width: auto;
    max-width: 100%;
  }

  text {
    
  }
}

area {
  cursor: pointer;
}

/* Fonts
------------------------------------------------------------*/
html {
  transition: font-size .5s;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  background-color: white;
  color: variables.$COLOR_TEXT;
  font-family: variables.$FONT_BASE;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  line-height: map_get(variables.$BASE, line-height);
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
  /* font-feature-settings: "palt"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* text-align: justify;
  text-justify: inter-ideograph; */
  line-break: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;

  &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
  }

  &::-webkit-scrollbar-track {
      background: #F0F0F0;
  }

  &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .3);
      border-radius: 5px;
  }
}

input,
button,
textarea,
select {
  font-family: variables.$FONT_BASE;
  line-height: map_get(variables.$BASE, line-height);
}

.modContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
}


/* ==[ Base ]==================================== */
* {
  box-sizing: border-box;
  min-height: 0;
  min-width: 0;
  zoom: 1;

  &:before,
  &:after {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
    zoom: 1;
  }

  &:focus {
    outline: none !important;
  }
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  transition: all 0.3s ease;
}

main {
  display: block;
  margin: 0 auto;
  padding: 0;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

section {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    pointer-events: none;
  }
}

.sec-inner {
  padding: 80px 0;
  margin: 0;

  @include mixin.tabletP {
    padding: 60px 0;
  }
}

footer {
  margin-top: auto;
  width: 100%;
}

ol,
ul {
  list-style: none;
  margin: 0;
  outline: none !important;
}

p {
  margin-bottom: 0;
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

/* ==[ Bootstrap CSS ]==================================== */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
}

.container-full {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
}

.container-sm {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-right: 0;
  padding-left: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.card {
  border: none;
  border-radius: 0;
}

.card-img {
  border-radius: 0;

  img {
    width: 100%;
  }

  /* 高さ設定あり */
  &.ratio {
    position: relative;

    &::before {
      content: "";
      display: block;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.card-header {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
}

.card-body {
  padding: 0;
}

.card-footer {
  background-color: transparent;
  border-top: 0;
  padding: 0;
}

.card-title {
  margin-bottom: 0;
}

/* 8の倍数Mixin使用時の記述 */
/* [class^="col"] {
  @include mixin.multiple-p('left', '2');
  @include mixin.multiple-p('right', '2');
  @include mixin.multiple-p(null, '2');
} */
