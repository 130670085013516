@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: map_get(variables.$BASE, line-height);
}

h1 {
    font-size: calc(30px + 10 * (100vw - 320px) / 1600);
}

h2 {
    font-size: calc(28px + 6 * (100vw - 320px) / 1600);
}

h3 {
    font-size: calc(22px + 6 * (100vw - 320px) / 1600);
}

h4 {
    font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}

h5 {
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
}

h6 {
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}

.c-contxt {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  margin-bottom: 30px;
  text-align: center;

  @include mixin.tabletP {
    text-align: left;
  }
}

/* 20230626カーリースLP(本サイト・軽自動車.com)  */
.cl-lead {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: 1.45;

  &.fz24 {
    font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  }

  @include mixin.tabletP {
    text-align: left;
  }
}

.cl-heading {
  &__red {
    border: 2px variables.$COLOR_MAIN solid;
    border-radius: 36px;
    color: variables.$COLOR_MAIN;
    font-size: calc(14px + 10 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding: 6px 10px;
    text-align: center;

    @include mixin.tabletP {
      padding: 8px 10px;
    }
  }

  &__white,
  &__skyblue {
    border-radius: 36px;
    font-size: calc(14px + 8 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    padding: 15px;
    text-align: center;
    margin-top: 30px;

    @include mixin.tabletP {
      border-radius: 72px;
      padding: 20px 50px;
      text-align: left;
    }

    @media screen and (max-width: 375px ) {
      padding: 20px;
    }
  }

  &__white {
    background-color: white;
  }

  &__skyblue {
    background-color: #E5F4FA;
  }

  &__yellow,
  &__blue {
    border-radius: 26px;
    font-size: calc(18px + 4 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    padding: 6px 10px;
    margin-bottom: 20px;
    padding: 15px;
    text-align: center;
  }

  &__yellow {
    background-color: map_get(variables.$COLOR, gold);
  }

  &__blue {
    background-color: map_get(variables.$COLOR, turquoise);
    color: white;
  }
}

.text-black {
  color: black !important;
}

.text-danger {
  color: variables.$COLOR_MAIN !important;
}

.text-yellow {
  color: #FFD52D;
}