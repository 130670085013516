@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.wpcf7c-btn-confirm,
.wpcf7c-btn-back,
.wpcf7-submit {
  border: none;
  border-radius: 50px;
  color: white;
  display: inline-block;
  padding: 15px;
  margin: 0;
  width: 320px;
  max-width: 100%;

  &:hover {
    opacity: .7;
  }
}

.wpcf7-submit,
[type="submit"] {
  background-color: variables.$COLOR_TEXT;
}

.wpcf7c-btn-back {
  background-color: map_get(variables.$COLOR, dark-gray);
}

[class^="btn"] {
  border-radius: 30px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  max-width: 100%;

  +[class^="btn"] {
    margin-top: 20px;
  }
}

.btn {
  border: 1px solid;
  margin: 30px auto 0;
  padding: 15px 10px;
  text-decoration: none;
  width: 320px;

  &.type1 {
    border-color: variables.$COLOR_MAIN;
    color: variables.$COLOR_MAIN;
    transition-duration: .4s;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &::after {
      content: "";
      display: block;
      background: variables.$COLOR_MAIN;
      border-radius: 50%;
      margin: auto;
      width: 100%;
      padding-top: 100%;
      height: 0;
      pointer-events: none;
      transform: translateY(-50%) scale(0.1);
      transition: opacity .5s, transform 0s;
      transition-delay: 0s, .4s;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: -1;
    }

    &:hover {
      color: #fff;
      opacity: 1;

      &::after {
        opacity: 1;
        transform: translateY(-50%) scale(1.1);
        transition-delay: 0s;
        transition: opacity .8s, transform .5s ease-in-out;
      }
    }

    &.-white {
      border-color: white;
      color: white;

      &::after {
        background: white;
      }

      &:hover {
        color: variables.$COLOR_TEXT;

      }
    }
  }

  &.type2 {
    background-color: variables.$COLOR_TEXT;
    border-color: variables.$COLOR_TEXT;
    color: white;
  }

  &.type3 {
    background-color: map_get(variables.$COLOR, blue);
    border-color: map_get(variables.$COLOR, blue);
    color: white;
  }

  &.type4 {
    background-color: map_get(variables.$COLOR, green);
    border-color: map_get(variables.$COLOR, green);
    color: white;
  }

  &.type5 {
    background-color: map_get(variables.$COLOR, gold);
    border-color: map_get(variables.$COLOR, gold);
    color: white;
  }

  &.type6 {
    background-color: variables.$COLOR_MAIN;
    border-color: variables.$COLOR_MAIN;
    color: white;
  }
}

.btn-pulldown {
  width: 396px;
  position: relative;

  &::after {
    background: url(#{mixin.set_common_path('icon_pulldown.svg')}) no-repeat center center;
    background-size: 100% auto;
    content: "";
    display: block;
    width: 19px;
    height: 11px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.btn-red,
.btn-black {
  color: white;
  margin: 0 auto;
  padding: 15px 10px;
  width: 380px;
  max-width: 100%;

  @include mixin.sp {
    padding: 10px;
  }

  &:hover {
    color: white;
  }
}

.btn-red {
  background-color: variables.$COLOR_MAIN;
}

.btn-black {
  background-color: variables.$COLOR_TEXT;
}

.btn-white {
  background-color: white;
  border-radius: 36px;
  color: variables.$COLOR_TEXT;
  padding: 20px 10px;
  width: 380px;

  &:hover {
    color: variables.$COLOR_TEXT;
  }

  +[class^="btn"] {
    margin-top: 0;
  }
}

.btn-prev,
.btn-next {
  background-color: transparent;
  border: 1px map_get(variables.$COLOR, dark-gray) solid;
  border-radius: 6px;
  padding: 2px 15px;

  span {
    color: map_get(variables.$COLOR, dark-gray);
    display: inline-block;
    position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
      }
    }

  &:hover {
    opacity: .7;
  }

  &.active {
    border-color: variables.$COLOR_TEXT;

    span {
      color: variables.$COLOR_TEXT;
    }
  }
}

.btn-prev {
  span {
    padding-left: 15px;

    &::before {
      background: url(#{mixin.set_common_path('icon_arrow_gray.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 6px;
      height: 10px;
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.btn-next {
  margin-left: auto;

  span {
    padding-right: 15px;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_black.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 6px;
      height: 10px;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.txt-link {
  display: inline-block;
  font-family: variables.$FONT_ALPHA;
  font-weight: 700;
  padding-right: 40px;
  line-height: 1;
  text-decoration: none;
  position: relative;

  &::after {
    background: url("#{mixin.set_common_path('icon_arrow.svg')}") no-repeat center center;
    content: "";
    display: block;
    width: 21px;
    height: 8px;
    position: absolute;
    bottom: 3px;
    right: 10px;
    transition: .3s;
  }

  &:hover {
    color: variables.$COLOR_TEXT;

    &::after {
      right: 0;
      transition: .3s;
    }
  }
}
