@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

body {
	&.-noscroll {
		overflow: hidden;
		@include mixin.tabletP {
			height: 100vh;
			left: 0;
			position: fixed;
			width: 100%;
		}
	}
}

#wrapper {
	overflow: hidden;
}
