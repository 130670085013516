@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

#footer {
  background-color: variables.$COLOR_TEXT;
  color: white;
  padding: 40px 0 0;

  @include mixin.tabletP {
    text-align: center;
  }

  .c-ttl-unit {
    text-align: left;

    @include mixin.tabletP {
      text-align: center;
    }
  }

  .p-address-unit {
    margin-bottom: 30px;
  }

  .p-link-unit {
    margin-bottom: 30px;

    .tel-link {
      color: white;
    }
  }

  .sns-unit {
    display: flex;
    align-items: center;
    gap: 0 20px;

    @include mixin.tabletP {
      justify-content: center;
    }
  }

  .p-top-nav {

    .navbar-nav-wrap {

      +.navbar-nav-wrap {
        margin-top: 20px;
      }
    }

    .nav-heading {
      color: variables.$COLOR_MAIN;
      font-family: variables.$FONT_ALPHA;
      font-weight: 800;
      line-height: normal;
    }

    .nav-link {
      color: white;
      text-decoration: none;
      padding: 0;
    }
  }

  .p-bottom-nav {
    .navbar-nav {
      flex-direction: row;
      gap: 0 50px;

      @include mixin.tabletP {
        flex-direction: column;
        justify-content: center;
        gap: 10px 0;
        margin-bottom: 30px;
      }

      .copyright {
        color: map_get(variables.$COLOR, dark-gray);
        font-size: calc(12px + 2 * (100vw - 320px) / 1600);
      }

      .nav-link {
        color: white;
        font-size: calc(12px + 2 * (100vw - 320px) / 1600);
        text-decoration: underline;
        padding: 0;
      }
    }
  }

  .p-top-appli {
    margin: 60px 0 30px;

    .p-apple-unit {
      background-color: white;
      border: none;

      .p-point-list {
        li {
          color: variables.$COLOR_TEXT;
        }
      }
    }
  }

  .nav-item-child {
    a {
      &::before {
        content: ' - ';
      }
    }
  }
}

#pagetop {
  @include mixin.tabletP {
    margin-top: 30px;

  }
}

.floating-nav {
  border-top: 1px #707070 solid;
  background-color: variables.$COLOR_TEXT;
  display: none;
  padding: 0;

  @include mixin.tabletP {
    display: block;
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .nav-item {
    text-align: center;
    width: calc(100% / 3);

    +.nav-item {
      border-left: 1px #707070 solid;
    }
  }

  .nav-link {
    color: white;
    font-size: 12px;
    text-decoration: none;
    padding: 15px 10px;
    padding-bottom: 5px;

    &::before {
      content: "";
      display: block;
      margin:  0 auto;
      width: 30px;
      height: 26px;
    }

    &.link-shop {
      &::before {
        background: url("#{mixin.set_image_path('footer/icon_mapping.svg')}");
        background-size: 100% auto;
      }
    }

    &.link-search {
      &::before {
        background: url("#{mixin.set_image_path('footer/icon_search_white.svg')}");
        background-size: 100% auto;
      }
    }

    &.link-reserve {
      &::before {
        background: url("#{mixin.set_image_path('footer/icon_calendar.svg')}");
        background-size: 100% auto;
      }
    }
  }
}

/* フローティングバナー 20240523追加 */
#floating {
  position: fixed;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  transition: .4s ease-in-out;
  z-index: 100;

  .card {
    background-color: transparent;
    padding: 11px 8px 0;
    position: relative;

    &-closed {
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        opacity: .8;
      }
    }

    &-header {
      background-color: #DF121A;
      border: 3px #DF121A solid;
      border-radius: 6px 6px 0 0;
      padding: 5px 8px;
    }

    &-title {
      color: white;
      text-align: center;
    }

    &-body {
      background-color: white;
      border: 3px #DF121A solid;
      border-radius: 0 0 6px 6px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px 0;
      padding: 10px;
    }

  }
  
}
