@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/* 下層テンプレート */
.modContentsContainer {
  padding-bottom: 50px;
}

.sec-inner {
  &.modContainer-inner {
    padding: 80px 0 30px;

    @include mixin.tabletP {
      padding: 60px 0 0;
    }
  }
}


.modContents {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before,
  h6:before,
  h1:after,
  h2:after,
  h3:after,
  h4:after,
  h5:after,
  h6:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: none;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  h2 {
    border-bottom: 4px map_get(variables.$COLOR, gray) solid;
    font-size: calc(28px + 2 * (100vw - 320px) / 1600);
    padding-bottom: 15px;
    line-height: normal;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 280px;
      height: 4px;
      background-color: variables.$COLOR_MAIN;
      position: absolute;
      bottom: -4px;
      left: 0;

      @include mixin.tabletP {
        width: 140px;
      }
    }
  }

  h3:not([class^="toggle_panel"]) {
    border-left: 4px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_MAIN;
    font-size: calc(22px + 6 * (100vw - 320px) / 1600);
    padding-left: 15px;
    line-height: 1;
  }

  h4 {
    font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  }

  h5 {
    border-bottom: 2px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_MAIN;
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    padding-bottom: 5px;
  }

  h6 {
  }

  p:not(.card-title):not(.pp-txt):not(.ranking-card p):not(.flow-unit p):not(.card p) {
    margin-bottom: 40px;

    &.sdgs_ttl {
      font-size: calc(28px + 4 * (100vw - 320px) / 1600);
      color: rgb( 0, 125, 200 );
      text-align: center;
      font-weight: bold;

      @include mixin.tabletP {
        font-size: calc(21px + 4 * (100vw - 320px) / 1600);
      }
    }
  }

  .lineup_comment {
    margin-bottom: 50px;
    p {
      margin-bottom: 10px !important;
    }
  }

  ul:not(.list_1):not(.list_1 li ul):not(.wp-pagenavi):not(.modListJump):not(.nav-tabs):not(.qr-unit):not(.modFlex):not(.list__tabl) {
    margin-bottom: 50px;
    list-style: none;

    li {
      padding-left: 30px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      @include mixin.tabletP {
        padding-left: 30px;
      }

      &::before {
        background: variables.$COLOR_MAIN;
        border-radius: 50%;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 0;

        @include mixin.tabletP {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  ol:not(.flow-unit) {
    list-style: none;
    counter-reset: ol_li;
    padding-left: 0;
    margin-bottom: 50px;

    li {
      padding-left: 40px;
      margin-bottom: 10px;
      position: relative;

      @include mixin.tabletP {
        padding-left: 30px;
      }

      &::before {
        background-color: variables.$COLOR_MAIN;
        color: white;
        counter-increment: ol_li;
        content: "" counter(ol_li);
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0;
        left: 0;

        @include mixin.tabletP {
          line-height: 20px;
          width: 20px;
          height: 20px;
          top: 3px;
        }
      }
    }
  }

  dl.news-list {
    border-bottom: 4px #191F1D dotted;
    padding: 20px 0;

    dt {
      &.date {
        font-size: 16px;
        font-weight: normal;
      }
    }

    .post-cont {
      a {
        color: #1A1311;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        &:hover {
          color: #447067;
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
  }

  .wp-caption,
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;

    &[src*=wp-emoji-one] {
      width: 16px;
      height: 16px;
    }
  }

  .wp-caption {
    p {
      margin: 0;
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-break: anywhere;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {}

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {}

  video,
  iframe {
    width: 100%;
    max-width: 100%;
  }

  a {
    color: #0085cd;
    font-weight: normal;

    &:hover {
      transition: .5s ease;
    }

    &:visited {
    }
  }
  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt,
  .btn_link {
    color: variables.$COLOR_TEXT;
    display: inline-block;
    font-weight: 400;
    margin: 0 5px;
    text-decoration: underline;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      color: map_get(variables.$COLOR, blue);
      opacity: 1;
    }
  }

  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt {
    padding-right: 160px;

    @include mixin.tabletL {
      padding-right: 130px;
    }

    @include mixin.tabletP {
      padding-right: 110px;
    }

    &::after {
      width: 146px;
      height: 29px;

      @include mixin.tabletL {
        width: 120px;
        height: 24px;
      }

      @include mixin.tabletP {
        width: 100px;
        height: 20px;
      }
    }
  }

  .btn_pdf {
    &::after {
      background: url(#{mixin.set_common_path('bg_pdf_off.svg')}) no-repeat center;
      background-size: 100% auto;
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('bg_pdf_on.svg')}) no-repeat center;
        background-size: auto 100%;
      }
    }
  }

  .btn_doc {
    &::after {
      background: url(#{mixin.set_common_path('bg_doc_off.svg')}) no-repeat center;
      background-size: 100% auto;
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('bg_doc_on.svg')}) no-repeat center;
        background-size: 100% auto;
      }
    }
  }

  .btn_xls {
    &::after {
      background: url(#{mixin.set_common_path('bg_xls_off.svg')}) no-repeat center;
      background-size: 100% auto;
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('bg_xls_on.svg')}) no-repeat center;
        background-size: 100% auto;
      }
    }
  }

  .btn_ppt {
    background: url(#{mixin.set_common_path('bg_ppt_off.svg')}) no-repeat right center;
    background-size: auto 100%;
  }

  .btn_link {
    padding-right: 35px;

    @include mixin.tabletP {
      padding-right: 20px;
    }

    &::after {
      background: url(#{mixin.set_common_path('bg_blank_off.svg')}) no-repeat center;
      background-size: 100% auto;
      width: 20px;
      height: 16px;

      @include mixin.tabletP {
        width: 15px;
        height: 12px;
      }
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('bg_blank_on.svg')}) no-repeat center;
      }
    }
  }

  .btn1,
  .btn2 {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    padding: 10px;
    text-align: center;
    padding: 15px 10px;
    margin-bottom: 50px;
    width: 320px;
    max-width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .btn1 {
    background-color: white;
    border-color: variables.$COLOR_MAIN;
    color: variables.$COLOR_MAIN;

    &:hover {
      background-color: variables.$COLOR_MAIN;
      color: white;
    }
  }

  .btn2 {
    background-color: variables.$COLOR_TEXT;
    border-color: variables.$COLOR_TEXT;
    color: white;

    &:hover {
      color: white;
    }
  }

  .btn3 {
    color: variables.$COLOR_TEXT;
    display: inline-block;
    font-family: variables.$FONT_ALPHA;
    font-weight: 700;
    padding-right: 40px;
    margin: 0;
    margin-bottom: 50px;
    line-height: 1;
    text-decoration: none;
    position: relative;

    &::after {
      background: url("#{mixin.set_common_path('icon_arrow.svg')}") no-repeat center center;
      content: "";
      display: block;
      width: 21px;
      height: 8px;
      position: absolute;
      bottom: 3px;
      right: 10px;
      transition: .3s;
    }

    &:hover {
      color: variables.$COLOR_TEXT;

      &::after {
        right: 0;
        transition: .3s;
      }
    }
  }

  table:not(.p-form-unit table):not([class^="toggle_panel"]):not(.req-tbl) {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;
    height: auto !important;

    tr {
      height: auto !important;
    }

    th,
    td {
      border: 1px white solid;
      padding: 20px;
      height: auto !important;

      @include mixin.tabletP {
        padding: 10px 15px;
        margin-bottom: 1px;
      }
    }

    th {
      background-color: variables.$COLOR_TEXT;
      color: white;
      font-weight: 700;
      vertical-align: middle;
      width: 30%;
    }

    td {
      background-color: map_get(variables.$COLOR, gray);
      width: 70%;
    }

    .wp-caption {
      p {
        margin: 0;
      }

      img {
        margin: 0 auto;
        padding: 0;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &.tbl_none {
      border: none;
      display: table;
      table-layout: fixed;

      tr {
        &:first-child {
          th,td {
            background-color: transparent;
            border: none;
          }
        }
      }

      th,
      td {
        background: none;
        border: none;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;

        p {
          font-weight: normal;
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      border: none;
      table-layout: fixed;

      @include mixin.tabletP {

        th,td {
          border: none;
        }
      }
    }
  }

  table.toggle_panel {
    border-collapse: collapse;
    border: none;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    position: relative;

    +.toggle_panel {
      margin-top: 10px;
    }

    &.last-tbl {
      margin-bottom: 60px;
    }

    > {
      tbody>tr> {

        th,
        td {
          background: none;
          border: none;
          vertical-align: top;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }

      tr {
        > {

          th,
          td {
            background: none;
            border: none;
            vertical-align: top;
            text-align: left;
            margin: 0;
            padding: 0;
          }
        }

        &:nth-child(1)>td {
          position: relative;
          cursor: pointer;
        }
      }

      tbody>tr:nth-child(1)>td {
        position: relative;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }
    }

    tr {
      &:nth-child(1) {

        >td {
          h3 {
            background-color: map_get(variables.$COLOR, gray);
            border-left: none;
            border-radius: 6px;
            color: variables.$COLOR_TEXT;
            font-size: calc(16px + 2 * (100vw - 320px) / 1600);
            font-weight: 400;
            margin: 0;
            padding: 20px 50px;
            line-height: normal;

            &::before {
              color: variables.$COLOR_MAIN;
              content: "Q";
              display: inline-block;
              font-family: variables.$FONT_ALPHA;
              font-size: calc(20px + 4 * (100vw - 375px) / 1545);
              font-weight: 700;
              position: absolute;
              top: 18px;
              left: 20px;

              @include mixin.tabletP {
                top: 20px;
              }
            }

            &:after {
              background:  url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
              content: "";
              height: 100%;
              position: absolute;
              width: 18px;
              height: 18px;
              top: 22px;
              right: 20px;
              transform: rotate(180deg);
              transition: .5s;
            }

            &.toggle_open {
              &::after {
                background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
                transform: rotate(-180deg);
                transition: .5s;
              }
            }
          }
        }
      }
    }

    > {

      tr:nth-child(2)>td,
      tbody>tr:nth-child(2)>td {
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        padding: 30px 50px;
        display: none;
        position: relative;

        &::before {
          color: variables.$COLOR_MAIN;
          content: "A";
          display: inline-block;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(20px + 4 * (100vw - 375px) / 1545);
          font-weight: 700;
          position: absolute;
          top: 28px;
          left: 20px;
        }
      }
    }
  }

  table.toggle_panel2 {
    border-collapse: collapse;
    border: none;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    position: relative;

    +.toggle_panel2 {
      margin-top: 10px;
    }

    &.last-tbl {
      margin-bottom: 60px;
    }

    > {
      tbody>tr> {

        th,
        td {
          background: none;
          border: none;
          vertical-align: top;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }

      tr {
        > {

          th,
          td {
            background: none;
            border: none;
            vertical-align: top;
            text-align: left;
            margin: 0;
            padding: 0;
          }
        }

        &:nth-child(1)>td {
          position: relative;
          cursor: pointer;
        }
      }

      tbody>tr:nth-child(1)>td {
        position: relative;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }
    }

    tr {
      &:nth-child(1) {

        >td {
          h3 {
            background-color: map_get(variables.$COLOR, gray);
            border-left: none;
            border-radius: 6px;
            color: variables.$COLOR_TEXT;
            font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
            font-weight: 700;
            margin: 0;
            padding: 20px 50px;
            padding-left: 30px;
            line-height: normal;

            &::before {
              display: none;
            }

            &:after {
              background:  url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
              content: "";
              height: 100%;
              position: absolute;
              width: 18px;
              height: 18px;
              top: 28px;
              right: 20px;
              transform: rotate(180deg);
              transition: .5s;
            }

            &.toggle_open {
              &::after {
                background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
                transform: rotate(-180deg);
                transition: .5s;
              }
            }
          }
        }
      }
    }

    > {

      tr:nth-child(2)>td,
      tbody>tr:nth-child(2)>td {
        font-size: calc(14px + 2 * (100vw - 320px) / 1600);
        padding: 30px 0;
        display: none;
        position: relative;

        &::before {
          display: none;
        }
      }
    }
  }

  .flow-unit {
    margin-bottom: 50px;

    li {
      box-sizing:border-box;
      display: flex;
      position: relative;

      .flow-num {
        color: variables.$COLOR_MAIN;
        display: block;
        flex-grow:1;
        font-family: variables.$FONT_ALPHA;
        font-size: calc(20px + 4 * (100vw - 320px) / 1600);
        font-weight: 700;
        letter-spacing: 2px;
        text-align: center;
        width: 200px;
        position: relative;

        @include mixin.tabletP {
          line-height: normal;
          width: 100px;
        }

        @include mixin.sp {
          width: 80px;
        }

        &::before {
          content: "Flow";
          display: inline-block;
          padding-right: 10px;

          @include mixin.tabletP {
            display: block;
            padding-right: 0;
          }
        }

        &::after {
          background: url(#{mixin.set_common_path('img_flow_line.svg')}) repeat-y center bottom;
          content: "";
          display: inline-block;
          width: 3px;
          height: calc(100% - 40px);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          @include mixin.tabletP {
            height: calc(100% - 50px);
          }
        }
      }

      &:last-of-type {
        span {
          &::after {
            display: none;
          }
        }

        >div {
          padding-bottom: 0;
        }
      }

      .flow-ttl {
        font-size: calc(18px + 10 * (100vw - 320px) / 1600);
        margin-bottom: 20px;
        line-height: normal;
        font-weight: 700;
      }

      >div {
        flex-grow:1;
        padding-bottom: 50px;
        width: calc(100% - 200px);

        @include mixin.tabletP {
          width: calc(100% - 100px);
        }

        @include mixin.sp {
          width: calc(100% - 80px);
        }

        >p {
          font-weight: normal;
          margin-bottom: 0;
          line-height: normal;
        }
      }
    }
  }

}

.list_1 {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
  margin-bottom: 50px;
  height: auto;

  @include mixin.tabletP {
    gap: 30px 0;
  }

  >li {
    list-style: none;
    height: auto;
    width: calc(100% / 3 - 30px);

    @include mixin.tabletL {
      width: calc(100% / 2 - 15px);
    }

    @include mixin.tabletP {
      width: 100%;
    }

    h5 {
      flex-grow: 1;
    }

    >ul {
      margin-left: 20px;

      @include mixin.tabletP {
        margin-left: 0;
      }

      >li {
        border: none;
        padding-bottom: 10px;

        &::before {
          display: none;
        }

        a {
          color: variables.$COLOR_TEXT;
          display: inline-block;
          flex-grow: 1;
          text-decoration: none;
          line-height: normal;
          padding-left: 5px;
          text-decoration: underline;
          text-decoration-skip-ink: auto;
          position: relative;

          &::before {
            content: "-";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:hover {
            color: map_get(variables.$COLOR, blue);
            opacity: 1;
          }
        }
      }
    }
  }
}

@include mixin.tabletP {
  .modContents {

    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 10px;
    }

    .size-full {
      margin: 0 auto 10px;
      width: 100%;
    }

    .scroll_wrap {
      margin-bottom: 30px;
      width: 100%;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;

      &:before {
        content: "→スクロールできます。"
      }
    }

    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }

    .tbl_responsive,
    .tbl_responsive thead,
    .tbl_responsive tfoot,
    .tbl_responsive tbody,
    .tbl_responsive tr,
    .tbl_responsive th,
    .tbl_responsive td {
      display: inline-block;
      width: 100% !important;
    }

    .tbl_responsive02 th,
    .tbl_responsive02 td {
      display: inline-block;
      width: 50% !important;
    }
  }
}

.mb_block {
  margin-bottom: 60px;
}
